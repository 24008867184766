<template>
  <div :class="`cost-bottom flex ${width}`">
    <div class="cost-bt-bg"></div>
    <div class="cost-bt-body items-center w-full justify-center">
      <span>{{ message }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      required: false,
    },
    width: {
      required: false,
    },
  },
}
</script>
