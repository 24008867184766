<template>
  <div class="grid gap-4 grid-cols-2 t_list mx-5">
    <div class="text-left">
      <span :class="title_class">{{ title }}</span>
    </div>
    <div class="text-right">
      <i :class="icon"></i>
      <span :class="value_class">
        <slot>{{ value }}</slot>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      required: false,
    },
    value: {
      required: false,
    },
    icon: {
      required: false,
    },
    value_class: {
      required: false,
    },
  },
  data() {
    return {
      title_class: 't_title',
    }
  },
}
</script>

<style lang="scss" scoped>
.t_list {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
  padding-top: 10px;
  min-height: 50px;
}
.t_title {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #2e2e39;
}
.value_icon {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #00b225;
}
.normal_value {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
.blue_value {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #372eff;
}
.green {
  color: #00b225;
}
</style>
