<template>
  <div>
    <t-modal
      :name="mid"
      variant="subscription"
      :hideCloseButton="true"
      :disableBodyScroll="true"
      ref="popup"
    >
      <section>
        <!-- <section
          class="w-full h-auto px-4 py-3 mt-2 mb-2 bg-white rounded-md shadow"
        > -->
        <transaction-card :amount="transaction.amount" />
        <transaction-list
          title="Payment For"
          :value="transaction.payment_for"
          icon="fas fa-credit-card mr-3 green"
          value_class="value_icon"
        />

        <transaction-list
          title="Payment Method"
          :value="transaction.payment_method"
          value_class="normal_value"
        />

        <transaction-list
          title="Payer Name"
          :value="`--`"
          value_class="normal_value"
        >
          <router-link
            v-if="transaction.user"
            class="font-bold text-blue-600"
            :to="{
              name: getViewName(transaction.user.role_name),
              params: { id: transaction.user.id },
            }"
            target="_blank"
          >
            {{ transaction.user.full_name }}
          </router-link>
        </transaction-list>

        <!-- <transaction-list
          title="Payer ID"
          :value="transaction.user ? transaction.user.id : '--'"
          value_class="blue_value"
        /> -->

        <transaction-list
          title="Payer Contact"
          :value="transaction.user ? transaction.user.phone_number : '--'"
          value_class="normal_value"
        />

        <transaction-list
          title="Transaction Time"
          :value="
            getFormattedDateTime(transaction.date, 'D MMM, YYYY hh:mm:ss a')
          "
          value_class="normal_value"
        />

        <transaction-list
          title="Transaction ID"
          :value="transaction.id"
          value_class="normal_value"
        />

        <transaction-list
          title="Tax ID"
          :value="transaction.tax_id ? transaction.tax_id : '--'"
          value_class="normal_value"
        />

        <transaction-list
          title="Refunded Amount"
          :value="
            transaction.is_refunded
              ? getFormattedPrice(transaction.amount)
              : '--'
          "
          value_class="normal_value"
        />

        <transaction-list
          title="Refund Status"
          :value="
            transaction.refund_status && transaction.refund_status !== ''
              ? transaction.refund_status
              : '--'
          "
          value_class="normal_value"
        />

        <transaction-list
          title="Client IP"
          :value="
            transaction.client_ip_address ? transaction.client_ip_address : '--'
          "
          value_class="normal_value"
        />

        <transaction-list
          title="Fingerprint"
          :value="
            transaction.payment_method_details &&
            transaction.payment_method_details.fingerprint
              ? transaction.payment_method_details.fingerprint
              : '--'
          "
          value_class="normal_value"
        />

        <transaction-list
          title="Card Origin Country"
          :value="
            transaction.payment_method_details &&
            transaction.payment_method_details.card_origin_country
              ? transaction.payment_method_details.card_origin_country
              : '--'
          "
          value_class="normal_value"
        />

        <transaction-list
          title="Card Expire Date"
          :value="
            transaction.payment_method_details &&
            transaction.payment_method_details.expiry
              ? getFormattedDateTime(
                  transaction.payment_method_details.expiry,
                  'D MMM, YYYY'
                )
              : '--'
          "
          value_class="normal_value"
        />

        <transaction-list
          title="Card Extra Details"
          :value="
            transaction.payment_method_details &&
            transaction.payment_method_details.extra_detail
              ? transaction.payment_method_details.extra_detail
              : '--'
          "
          value_class="normal_value"
        />

        <cost-message-box
          :message="
            `Trasaction of $${transaction.amount} has successfully completed`
          "
          width="width-50"
        />
      </section>
      <div class="flex items-center justify-end px-4 py-4 mt-8">
        <anchor-button variant="secondary" @click="$refs.popup.hide()">
          Close
        </anchor-button>
      </div>
      <!-- </section> -->
    </t-modal>
  </div>
</template>

<script>
import { EventBus } from '@/utils/EventBus'
import { TransactionConfig } from '@/config/TransactionConfig'

import TransactionCard from '@/components/cards/TransactionCard.vue'
import CostMessageBox from '@/composites/trip/shared/CostMessageBox'
import TransactionList from '@/composites/transaction/shared/TransactionList.vue'

import AnchorButton from '@/components/form/AnchorButton.vue'
// import OtoTypography from "@/components/ui/OtoTypography.vue";
import { getFormattedDateTime } from '@/utils/datetime'

export default {
  name: 'ViewTransactionPopup',
  components: {
    AnchorButton,
    // OtoTypography,
    TransactionCard,
    CostMessageBox,
    TransactionList,
  },
  data() {
    return {
      mid: TransactionConfig.events.viewingData,
      transaction: {},
    }
  },
  computed: {},
  mounted() {
    EventBus.$on(TransactionConfig.events.viewingData, (item) => {
      console.log(item)
      this.transaction = item
      console.log(this.transaction)
    })
  },
  methods: {
    getFormattedDateTime,
    getCurrencySymbol() {
      if (this.transaction.currency) {
        return this.transaction.currency.symbol
      }

      return this.$org.default_currency.symbol
    },
    getViewName(role) {
      const ROLE_TO_VIEW_MAP = {
        '--': 'ViewRiderUserProfile',
      }

      return ROLE_TO_VIEW_MAP[role] || 'ViewOrganizationUserProfile'
    },
    getFormattedPrice(rawPrice) {
      let _price = rawPrice

      if (typeof rawPrice !== 'number') {
        _price = parseFloat(rawPrice).toFixed(2)

        if (isNaN(_price)) {
          return '--'
        }
      } else {
        _price = rawPrice.toFixed(2)
      }

      return `${this.getCurrencySymbol()} ${_price}`
    },
  },
}
</script>
